import * as api from './api';
import { handleApiError } from "core/utils";

export const suggestComment = (body) => async () => {
  try {
    return await api.suggestComment(body);
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};

export const generateAssignment = (body) => async () => {
  try {
    return await api.generateAssignment(body);
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};

export const generateRubrics = (body) => async () => {
  try {
    return await api.generateRubrics(body);
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};

export const continueConversation = (body) => async () => {
  try {
    const { data } = await api.continueConversation(body);
    return data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};

export const resetConversation = (body) => async () => {
  try {
    return await api.resetConversation(body);
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};


export const aiFeedback = (body) => async () => {
  try {
    const { data } = await api.aiFeedback(body);
    return data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};